import * as client_hooks from '../../../src/hooks.client.ts';


export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23'),
	() => import('./nodes/24'),
	() => import('./nodes/25'),
	() => import('./nodes/26'),
	() => import('./nodes/27'),
	() => import('./nodes/28'),
	() => import('./nodes/29'),
	() => import('./nodes/30'),
	() => import('./nodes/31'),
	() => import('./nodes/32'),
	() => import('./nodes/33'),
	() => import('./nodes/34'),
	() => import('./nodes/35'),
	() => import('./nodes/36'),
	() => import('./nodes/37'),
	() => import('./nodes/38'),
	() => import('./nodes/39'),
	() => import('./nodes/40'),
	() => import('./nodes/41'),
	() => import('./nodes/42'),
	() => import('./nodes/43'),
	() => import('./nodes/44'),
	() => import('./nodes/45'),
	() => import('./nodes/46'),
	() => import('./nodes/47'),
	() => import('./nodes/48'),
	() => import('./nodes/49'),
	() => import('./nodes/50'),
	() => import('./nodes/51'),
	() => import('./nodes/52'),
	() => import('./nodes/53'),
	() => import('./nodes/54'),
	() => import('./nodes/55'),
	() => import('./nodes/56'),
	() => import('./nodes/57'),
	() => import('./nodes/58'),
	() => import('./nodes/59'),
	() => import('./nodes/60'),
	() => import('./nodes/61'),
	() => import('./nodes/62'),
	() => import('./nodes/63'),
	() => import('./nodes/64'),
	() => import('./nodes/65'),
	() => import('./nodes/66'),
	() => import('./nodes/67'),
	() => import('./nodes/68'),
	() => import('./nodes/69'),
	() => import('./nodes/70'),
	() => import('./nodes/71'),
	() => import('./nodes/72'),
	() => import('./nodes/73'),
	() => import('./nodes/74'),
	() => import('./nodes/75'),
	() => import('./nodes/76'),
	() => import('./nodes/77'),
	() => import('./nodes/78'),
	() => import('./nodes/79'),
	() => import('./nodes/80'),
	() => import('./nodes/81'),
	() => import('./nodes/82'),
	() => import('./nodes/83'),
	() => import('./nodes/84'),
	() => import('./nodes/85'),
	() => import('./nodes/86'),
	() => import('./nodes/87'),
	() => import('./nodes/88'),
	() => import('./nodes/89'),
	() => import('./nodes/90'),
	() => import('./nodes/91'),
	() => import('./nodes/92'),
	() => import('./nodes/93'),
	() => import('./nodes/94'),
	() => import('./nodes/95'),
	() => import('./nodes/96'),
	() => import('./nodes/97'),
	() => import('./nodes/98'),
	() => import('./nodes/99'),
	() => import('./nodes/100'),
	() => import('./nodes/101'),
	() => import('./nodes/102'),
	() => import('./nodes/103'),
	() => import('./nodes/104'),
	() => import('./nodes/105'),
	() => import('./nodes/106'),
	() => import('./nodes/107'),
	() => import('./nodes/108'),
	() => import('./nodes/109'),
	() => import('./nodes/110'),
	() => import('./nodes/111'),
	() => import('./nodes/112'),
	() => import('./nodes/113'),
	() => import('./nodes/114'),
	() => import('./nodes/115'),
	() => import('./nodes/116'),
	() => import('./nodes/117'),
	() => import('./nodes/118'),
	() => import('./nodes/119'),
	() => import('./nodes/120'),
	() => import('./nodes/121'),
	() => import('./nodes/122'),
	() => import('./nodes/123'),
	() => import('./nodes/124'),
	() => import('./nodes/125'),
	() => import('./nodes/126'),
	() => import('./nodes/127'),
	() => import('./nodes/128'),
	() => import('./nodes/129'),
	() => import('./nodes/130'),
	() => import('./nodes/131'),
	() => import('./nodes/132'),
	() => import('./nodes/133'),
	() => import('./nodes/134'),
	() => import('./nodes/135'),
	() => import('./nodes/136'),
	() => import('./nodes/137'),
	() => import('./nodes/138'),
	() => import('./nodes/139'),
	() => import('./nodes/140'),
	() => import('./nodes/141'),
	() => import('./nodes/142'),
	() => import('./nodes/143'),
	() => import('./nodes/144'),
	() => import('./nodes/145'),
	() => import('./nodes/146'),
	() => import('./nodes/147'),
	() => import('./nodes/148'),
	() => import('./nodes/149'),
	() => import('./nodes/150'),
	() => import('./nodes/151'),
	() => import('./nodes/152'),
	() => import('./nodes/153'),
	() => import('./nodes/154'),
	() => import('./nodes/155'),
	() => import('./nodes/156'),
	() => import('./nodes/157'),
	() => import('./nodes/158'),
	() => import('./nodes/159'),
	() => import('./nodes/160'),
	() => import('./nodes/161'),
	() => import('./nodes/162'),
	() => import('./nodes/163'),
	() => import('./nodes/164'),
	() => import('./nodes/165'),
	() => import('./nodes/166'),
	() => import('./nodes/167'),
	() => import('./nodes/168'),
	() => import('./nodes/169'),
	() => import('./nodes/170'),
	() => import('./nodes/171'),
	() => import('./nodes/172'),
	() => import('./nodes/173'),
	() => import('./nodes/174'),
	() => import('./nodes/175'),
	() => import('./nodes/176'),
	() => import('./nodes/177'),
	() => import('./nodes/178'),
	() => import('./nodes/179'),
	() => import('./nodes/180'),
	() => import('./nodes/181'),
	() => import('./nodes/182'),
	() => import('./nodes/183'),
	() => import('./nodes/184'),
	() => import('./nodes/185'),
	() => import('./nodes/186'),
	() => import('./nodes/187'),
	() => import('./nodes/188'),
	() => import('./nodes/189'),
	() => import('./nodes/190'),
	() => import('./nodes/191'),
	() => import('./nodes/192'),
	() => import('./nodes/193'),
	() => import('./nodes/194'),
	() => import('./nodes/195'),
	() => import('./nodes/196'),
	() => import('./nodes/197'),
	() => import('./nodes/198'),
	() => import('./nodes/199'),
	() => import('./nodes/200'),
	() => import('./nodes/201'),
	() => import('./nodes/202'),
	() => import('./nodes/203'),
	() => import('./nodes/204'),
	() => import('./nodes/205'),
	() => import('./nodes/206'),
	() => import('./nodes/207'),
	() => import('./nodes/208'),
	() => import('./nodes/209'),
	() => import('./nodes/210'),
	() => import('./nodes/211'),
	() => import('./nodes/212'),
	() => import('./nodes/213'),
	() => import('./nodes/214'),
	() => import('./nodes/215'),
	() => import('./nodes/216'),
	() => import('./nodes/217'),
	() => import('./nodes/218'),
	() => import('./nodes/219'),
	() => import('./nodes/220'),
	() => import('./nodes/221'),
	() => import('./nodes/222'),
	() => import('./nodes/223'),
	() => import('./nodes/224'),
	() => import('./nodes/225'),
	() => import('./nodes/226')
];

export const server_loads = [0,25,28,27,29,30,31,32,34,37,2,4,5,6,7,8,9,10,11,12,13,14,15,16,17,23,18,20,24];

export const dictionary = {
		"/": [~41],
		"/admin": [~126,[25],[26]],
		"/admin/companies": [~127,[25],[26]],
		"/admin/companies/[companySlug]": [~128,[25],[26]],
		"/admin/companies/[companySlug]/edit": [~129,[25],[26]],
		"/admin/companies/[companySlug]/edit/accesses": [130,[25],[26]],
		"/admin/companies/[companySlug]/edit/licenses": [131,[25],[26]],
		"/admin/companies/[companySlug]/projects": [~132,[25],[26]],
		"/admin/companies/[companySlug]/users": [~133,[25],[26]],
		"/admin/country-risk": [~134,[25],[26]],
		"/admin/devtools": [135,[25],[26]],
		"/admin/devtools/details": [~136,[25],[26]],
		"/admin/emails": [~137,[25],[26]],
		"/admin/emails/email-dashboard": [~138,[25],[26]],
		"/admin/event-log": [~139,[25],[26]],
		"/admin/licenses": [140,[25],[26]],
		"/admin/licenses/susan": [~141,[25],[26]],
		"/admin/new-company": [142,[25],[26]],
		"/admin/questions": [~143,[25],[26]],
		"/admin/questions/add": [~153,[25],[26]],
		"/admin/questions/options": [154,[25],[26]],
		"/admin/questions/options/add": [~158,[25],[26]],
		"/admin/questions/options/[optionId]": [~155,[25],[26]],
		"/admin/questions/options/[optionId]/edit": [~156,[25],[26]],
		"/admin/questions/options/[optionId]/localizations/[locale]": [~157,[25],[26]],
		"/admin/questions/restructure": [~159,[25],[26]],
		"/admin/questions/sets": [~160,[25],[26]],
		"/admin/questions/sets/[questionSetId]": [~161,[25,28],[26]],
		"/admin/questions/sets/[questionSetId]/access": [~162,[25,28],[26]],
		"/admin/questions/sets/[questionSetId]/edit-details": [~163,[25,28],[26]],
		"/admin/questions/sets/[questionSetId]/print": [~164],
		"/admin/questions/[questionId]": [144,[25,27],[26]],
		"/admin/questions/[questionId]/details": [145,[25,27],[26]],
		"/admin/questions/[questionId]/edit": [146,[25,27],[26]],
		"/admin/questions/[questionId]/localizations": [147,[25,27],[26]],
		"/admin/questions/[questionId]/localizations/[locale]": [~148,[25,27],[26]],
		"/admin/questions/[questionId]/options": [~149,[25,27],[26]],
		"/admin/questions/[questionId]/scores": [~150,[25,27],[26]],
		"/admin/questions/[questionId]/ungc": [~151,[25,27],[26]],
		"/admin/questions/[questionId]/update-ungc": [~152,[25,27],[26]],
		"/admin/sent-emails/[email]/[emailType]": [~165],
		"/admin/suggested-follow-ups": [~166,[25],[26]],
		"/admin/suggested-follow-ups/[questionId]": [~167,[25,29],[26]],
		"/admin/suggested-follow-ups/[questionId]/localizations": [~168,[25,29],[26]],
		"/admin/taxonomy": [169,[25,30],[26]],
		"/admin/taxonomy/version": [190,[25,30],[26]],
		"/admin/taxonomy/version/[versionId]": [~191,[25,30],[26]],
		"/admin/taxonomy/year": [~192,[25,30],[26]],
		"/admin/taxonomy/[versionId]": [170,[25,30,31],[26]],
		"/admin/taxonomy/[versionId]/activities": [171,[25,30,31],[26]],
		"/admin/taxonomy/[versionId]/activities/[activityId]": [~172,[25,30,31],[26]],
		"/admin/taxonomy/[versionId]/activity-based": [~173,[25,30,31],[26]],
		"/admin/taxonomy/[versionId]/categories": [~174,[25,30,31],[26]],
		"/admin/taxonomy/[versionId]/categories/edit": [~175,[25,30,31],[26]],
		"/admin/taxonomy/[versionId]/company-wide": [~176,[25,30,31,32],[26]],
		"/admin/taxonomy/[versionId]/company-wide/text": [~177,[25,30,31,32],[26]],
		"/admin/taxonomy/[versionId]/expiration": [~178,[25,30,31],[26]],
		"/admin/taxonomy/[versionId]/kpi-texts": [179,[25,30,31,33],[26]],
		"/admin/taxonomy/[versionId]/kpi-texts/[kpi]": [~180,[25,30,31,33],[26]],
		"/admin/taxonomy/[versionId]/question-sets": [~184,[25,30,31],[26]],
		"/admin/taxonomy/[versionId]/question-sets/[setId]": [~185,[25,30,31],[26]],
		"/admin/taxonomy/[versionId]/question/[setId]/[questionId]": [~181,[25,30,31],[26]],
		"/admin/taxonomy/[versionId]/question/[setId]/[questionId]/localizations/[locale]": [~182,[25,30,31],[26]],
		"/admin/taxonomy/[versionId]/question/[setId]/[questionId]/update-scoring": [~183,[25,30,31],[26]],
		"/admin/taxonomy/[versionId]/reusable-group": [~186,[25,30,31],[26]],
		"/admin/taxonomy/[versionId]/reusable-group/[groupId]": [~187,[25,30,31],[26]],
		"/admin/taxonomy/[versionId]/sectors": [188,[25,30,31],[26]],
		"/admin/taxonomy/[versionId]/sectors/[sectorId]": [~189,[25,30,31],[26]],
		"/admin/transparency-act-steps": [~193,[25],[26]],
		"/admin/transparency-act-steps/add": [197,[25],[26]],
		"/admin/transparency-act-steps/checklist-item": [~198,[25],[26]],
		"/admin/transparency-act-steps/checklist-item/[checklistItemId]": [~199,[25],[26]],
		"/admin/transparency-act-steps/checklist-item/[checklistItemId]/add-localization": [~200,[25],[26]],
		"/admin/transparency-act-steps/[taStepId]": [~194,[25],[26]],
		"/admin/transparency-act-steps/[taStepId]/add-checklist-item": [195,[25],[26]],
		"/admin/transparency-act-steps/[taStepId]/add-localization": [~196,[25],[26]],
		"/admin/users": [~201,[25],[26]],
		"/admin/users/[userId]": [~202,[25],[26]],
		"/admin/users/[userId]/edit": [~203,[25],[26]],
		"/admin/workers": [~204,[25,34],[26]],
		"/data-processing-agreement": [205,[35]],
		"/login": [206],
		"/logout": [207],
		"/maintenance": [208],
		"/notifications": [~209,[36]],
		"/survey-invitation/[invitationId]": [~210,[37]],
		"/survey-invitation/[invitationId]/access-requested": [~211,[37]],
		"/survey-invitation/[invitationId]/company-created": [~212,[37]],
		"/survey-invitation/[invitationId]/declined": [214,[37]],
		"/survey-invitation/[invitationId]/decline": [213,[37]],
		"/survey-invitation/[invitationId]/forwarded": [216,[37]],
		"/survey-invitation/[invitationId]/forward": [215,[37]],
		"/survey-invitation/[invitationId]/new-company": [~217,[37]],
		"/survey-invitation/[invitationId]/new-company/access-requested": [218,[37]],
		"/survey-invitation/[invitationId]/no-access": [~219,[37]],
		"/survey-invitation/[invitationId]/request-access/[companyId]": [~220,[37]],
		"/survey-invitation/[invitationId]/request-access/[companyId]/access-requested": [~221,[37]],
		"/survey-invitation/[invitationId]/select-company": [~222,[37]],
		"/terms-of-use": [223,[38]],
		"/user": [224,[39],[40]],
		"/user/access-tokens": [~225,[39],[40]],
		"/user/access-tokens/create": [~226,[39],[40]],
		"/[companySlug]": [~42,[2],[3]],
		"/[companySlug]/admin/add-company": [~43,[2],[3]],
		"/[companySlug]/company": [~44,[2,4],[3]],
		"/[companySlug]/company/accesses": [~45,[2,4],[3]],
		"/[companySlug]/company/assessments": [~46,[2,4],[3]],
		"/[companySlug]/company/documents": [~47,[2,4],[3]],
		"/[companySlug]/company/edit": [48,[2,4],[3]],
		"/[companySlug]/company/licenses": [~49,[2,4],[3]],
		"/[companySlug]/company/overview": [~50,[2,4],[3]],
		"/[companySlug]/company/saq-results": [~51,[2,4],[3]],
		"/[companySlug]/company/tags": [~52,[2,4],[3]],
		"/[companySlug]/company/users": [~53,[2,4],[3]],
		"/[companySlug]/customers": [~54,[2],[3]],
		"/[companySlug]/customers/[customerId]": [~55,[2],[3]],
		"/[companySlug]/customers/[customerId]/overview": [56,[2,5],[3]],
		"/[companySlug]/customers/[customerId]/overview/messages": [~57,[2,5],[3]],
		"/[companySlug]/follow-ups": [58,[2,6],[3]],
		"/[companySlug]/follow-ups/received": [~59,[2,6],[3]],
		"/[companySlug]/follow-ups/received/[senderId]/[questionId]": [~60,[2],[3]],
		"/[companySlug]/follow-ups/sent": [~61,[2,6],[3]],
		"/[companySlug]/follow-ups/sent/[receiverId]/[questionId]": [~62,[2],[3]],
		"/[companySlug]/projects": [~63,[2,7],[3]],
		"/[companySlug]/projects/add": [~71,[2,7],[3]],
		"/[companySlug]/projects/[projectId]/(main)": [64,[2,7,8],[3]],
		"/[companySlug]/projects/[projectId]/(main)/chain/[chainId]": [~65,[2,7,8],[3]],
		"/[companySlug]/projects/[projectId]/(sub-pages)/edit-suppliers-list": [~66,[2,7],[3]],
		"/[companySlug]/projects/[projectId]/(sub-pages)/preview": [~67,[2,7],[3]],
		"/[companySlug]/projects/[projectId]/(sub-pages)/preview/print": [~68],
		"/[companySlug]/projects/[projectId]/(sub-pages)/send-survey": [~69,[2,7],[3]],
		"/[companySlug]/projects/[projectId]/(sub-pages)/settings": [~70,[2,7],[3]],
		"/[companySlug]/relogin": [~72],
		"/[companySlug]/saq/[questionSetId]": [~73,[9]],
		"/[companySlug]/saq/[questionSetId]/answer/[...questionPath]": [~74,[9]],
		"/[companySlug]/saq/[questionSetId]/finish": [~75,[10]],
		"/[companySlug]/suppliers": [~76,[2,11],[3]],
		"/[companySlug]/suppliers/add": [~91,[2,11],[3]],
		"/[companySlug]/suppliers/upload": [~92,[2,11],[3]],
		"/[companySlug]/suppliers/upload/[uploadId]": [~93,[2,11],[3]],
		"/[companySlug]/suppliers/upload/[uploadId]/status": [~94,[2,11],[3]],
		"/[companySlug]/suppliers/[supplierId]": [~77,[2,11],[3]],
		"/[companySlug]/suppliers/[supplierId]/edit": [~78,[2,11],[3]],
		"/[companySlug]/suppliers/[supplierId]/overview": [79,[2,11,12],[3]],
		"/[companySlug]/suppliers/[supplierId]/overview/documents": [~80,[2,11,12],[3]],
		"/[companySlug]/suppliers/[supplierId]/overview/documents/[uploadId]": [~81,[2,11,12],[3]],
		"/[companySlug]/suppliers/[supplierId]/overview/follow-ups": [~82,[2,11,12],[3]],
		"/[companySlug]/suppliers/[supplierId]/overview/history": [83,[2,11,12],[3]],
		"/[companySlug]/suppliers/[supplierId]/overview/messages": [~84,[2,11,12],[3]],
		"/[companySlug]/suppliers/[supplierId]/overview/results": [~85,[2,11,12],[3]],
		"/[companySlug]/suppliers/[supplierId]/overview/results/[projectId]": [~86,[2,11,12],[3]],
		"/[companySlug]/suppliers/[supplierId]/overview/results/[projectId]/print": [~87],
		"/[companySlug]/suppliers/[supplierId]/overview/results/[projectId]/ungc": [88,[2,11,12,13],[3]],
		"/[companySlug]/suppliers/[supplierId]/overview/results/[projectId]/ungc/category": [~89,[2,11,12,13],[3]],
		"/[companySlug]/suppliers/[supplierId]/overview/results/[projectId]/ungc/themes": [~90,[2,11,12,13],[3]],
		"/[companySlug]/surveys": [~95,[2],[3]],
		"/[companySlug]/surveys/[surveyId]": [~96,[14]],
		"/[companySlug]/surveys/[surveyId]/access-requested": [~97],
		"/[companySlug]/surveys/[surveyId]/answer": [~98,[14,15]],
		"/[companySlug]/surveys/[surveyId]/answer/[...questionPath]": [~99,[14,15]],
		"/[companySlug]/surveys/[surveyId]/finish": [~100,[16]],
		"/[companySlug]/surveys/[surveyId]/finish/thank-you": [~101,[16]],
		"/[companySlug]/surveys/[surveyId]/no-access": [~102],
		"/[companySlug]/taxonomy": [~103,[17]],
		"/[companySlug]/taxonomy/overview": [~121,[17,23]],
		"/[companySlug]/taxonomy/overview/import-report": [~122,[17,23]],
		"/[companySlug]/taxonomy/[reportId]": [104,[18]],
		"/[companySlug]/taxonomy/[reportId]/company/minimumSafeguards": [~105,[18,19]],
		"/[companySlug]/taxonomy/[reportId]/company/pais": [~106,[18,19]],
		"/[companySlug]/taxonomy/[reportId]/evaluate": [~107,[18]],
		"/[companySlug]/taxonomy/[reportId]/evaluate/[entityId]": [~108,[18,20]],
		"/[companySlug]/taxonomy/[reportId]/evaluate/[entityId]/[activityId]": [~109,[18,20]],
		"/[companySlug]/taxonomy/[reportId]/financial": [~110,[18,21]],
		"/[companySlug]/taxonomy/[reportId]/financial/activity": [~112,[18,21]],
		"/[companySlug]/taxonomy/[reportId]/financial/layout": [113,[18,21]],
		"/[companySlug]/taxonomy/[reportId]/financial/[entityId]/[activityId]": [~111,[18,21]],
		"/[companySlug]/taxonomy/[reportId]/manage": [~114,[18]],
		"/[companySlug]/taxonomy/[reportId]/manage/[entityId]": [~115,[18]],
		"/[companySlug]/taxonomy/[reportId]/report": [116,[18]],
		"/[companySlug]/taxonomy/[reportId]/start": [~117,[18]],
		"/[companySlug]/taxonomy/[reportId]/summary": [118,[18,22]],
		"/[companySlug]/taxonomy/[reportId]/summary/downloads": [~119,[18,22]],
		"/[companySlug]/taxonomy/[reportId]/summary/kpi": [~120,[18,22]],
		"/[companySlug]/ungc": [123,[2,24],[3]],
		"/[companySlug]/ungc/category": [~124,[2,24],[3]],
		"/[companySlug]/ungc/themes": [~125,[2,24],[3]]
	};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),

	reroute: (() => {})
};

export { default as root } from '../root.js';